class Accordion {
  constructor(domNode) {
    this.domNode = domNode;
    this.controlledNode = false;
    
    // see https://developer.chrome.com/articles/hidden-until-found/
    // see https://caniuse.com/mdn-html_global_attributes_hidden_until-found_value
    this.supportsHiddenUntilFound = 'onbeforematch' in document.body;
  }
  
  init() {
    const controlledNodeId = this.domNode.getAttribute('aria-controls');
    if (controlledNodeId) {
      this.controlledNode = document.getElementById(controlledNodeId);
    }
    
    if (this.domNode.getAttribute('aria-expanded') == null) {
      // default to aria-expanded="false"
      this.domNode.setAttribute('aria-expanded', 'false');
    }
    
    if (this.domNode.getAttribute('aria-expanded') === 'false') {
      this.controlledNode.setAttribute('hidden', 'until-found');
    }
      
    if (this.supportsHiddenUntilFound && this.controlledNode) {
      // modern Web standards allow for hidden="until-found" to also browser-search within
      // hidden elements, and revealing their content on match.
      // As of 2023-04-18, Chrome supports this feature, but Firefox does not.
      // This code adds this magical attribute and magical event handler only when
      // the browser supports this feature. Otherwise, default CSS rules for [hidden]
      // attribute would cause the content to not show up in non-supporting browsers even
      // when expanding the accordion (see gesis-accordion.scss).
      //
      // Additionally, because of Bootstrap silliness, gesis-bootstrap.scss has an
      // additional CSS rule for [hidden="until-found"].
      
      // console.log("this browser supports hidden=until-found");
      
      this.controlledNode.addEventListener('beforematch', this.handleBeforeMatch.bind(this));
    }
    
    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
    this.domNode.addEventListener('click', this.handleClick.bind(this));
  }
  
  showContent() {
    this.domNode.setAttribute('aria-expanded', 'true');
    this.controlledNode.removeAttribute('hidden');
  }
  
  hideContent() {
    this.domNode.setAttribute('aria-expanded', 'false');
    
    // when closing the accordion, add the hidden="until-found" attribute
    // again so the content can get revealed again on consecutive
    // search matches
    this.controlledNode.setAttribute('hidden', 'until-found');
  }
  
  toggleExpand() {
    if (this.domNode.getAttribute('aria-expanded') === 'true') {
      this.hideContent();
    }
    else {
      this.showContent();
    }
  }
  
  handleKeydown(event) {
    switch (event.code) {
      case "Enter":
      case "NumpadEnter":
      case "Space":
        this.toggleExpand();
        event.stopPropagation();
        event.preventDefault();
        break;
    }
  }
  
  handleClick() {
    this.toggleExpand();
  }
  
  handleBeforeMatch(event) {
    this.showContent();
  }
}

// accordionButtonElements = document.querySelectorAll('.accordion > button[aria-expanded][aria-controls]');
const initializeAccordions = function(accordionButtonElements) {
  const accordions = [];
  for (const accordionButtonElement of accordionButtonElements) {
    const accordion = new Accordion(accordionButtonElement);
    accordion.init();
    accordions.push(accordion);
  }
  return accordions;
};

export {
  Accordion,
  initializeAccordions,
};
